import React from 'react';
import Markdown from 'react-markdown';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section, Carousel } from 'components';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';

const HwabanPage = () => {
  const data = useStaticQuery(query);
  const imgList = data.allStrapiHwaban.nodes[0].carousel_img;
  const pageDescription = data.allStrapiHwaban.nodes[0].page_description;
  const box2 = {
    // filter: "brightness(50%)",
    backgroundColor: '#FAF1E5',
    width: '18vw',
    height: '13vw',
    padding: '25px',
    position: 'absolute',
    top: '12%',
    left: '47%',
    zIndex: '-1',
  };

  const farmToTable = (
    <Section>
      <div className='uk-flex' style={{ position: 'relative' }}>
        <div className='uk-width-xlarge uk-flex uk-flex-column'>
          <div
            className='uk-h2 uk-text-bold'
            uk-scrollspy='cls: uk-animation-fade'
          >
            Farm to Table, 레스토랑 정원을 만나다
          </div>
          <div className='uk-p' uk-scrollspy='cls: uk-animation-fade'>
            설악산책의 한식당 화반은 건강한 먹거리를 지향합니다. 화반 옆
            레스토랑 정원에서 직접 재배한 우리 농산물을 밥상에 올리고 있습니다.
            설악산책의 키친가든에서는 싱싱한 사계절 먹거리뿐 아니라, 아름다운
            조경으로 화려한 볼거리를 제공합니다.
          </div>
          <div
            className='uk-margin-medium-top'
            uk-scrollspy='cls: uk-animation-fade'
          >
            <StaticImage src='../images/garden/garden2.jpg' alt='garden2' />
          </div>
        </div>
        <div
          className='uk-visible@m'
          uk-scrollspy='cls: uk-animation-fade'
          style={box2}
        />
        <div className='uk-visible@m' uk-scrollspy='cls: uk-animation-fade'>
          <StaticImage
            src='../images/garden/garden5.jpg'
            alt='garden5'
            imgStyle={{ maxHeight: '600px', marginLeft: 'auto' }}
          />
        </div>
      </div>
    </Section>
  );

  const foodImages = (
    <>
      <div className='uk-width-1-2'>
        <GatsbyImage
          image={
            data.allStrapiHwaban.nodes[0].half_img1.localFile.childImageSharp
              .gatsbyImageData
          }
          alt=''
        />
      </div>
      <div className='uk-width-1-2'>
        <GatsbyImage
          image={
            data.allStrapiHwaban.nodes[0].half_img2.localFile.childImageSharp
              .gatsbyImageData
          }
          alt=''
        />
      </div>
    </>
  );

  return (
    <Layout>
      <Carousel
        imgList={imgList}
        pageTitle={'화반'}
        pageDescription={pageDescription}
      />
      {farmToTable}
      <Section title='식당 메뉴'>
        <div
          className='uk-flex uk-flex-center'
          uk-scrollspy='cls: uk-animation-fade'
        >
          <GatsbyImage
            image={
              data.allStrapiHwaban.nodes[0].menu_img.localFile.childImageSharp
                .gatsbyImageData
            }
            alt=''
          />
        </div>
      </Section>
      <Section>
        <div
          className=' uk-margin-small-bottom'
          uk-scrollspy='cls: uk-animation-fade'
        >
          <GatsbyImage
            image={
              data.allStrapiHwaban.nodes[0].main_img.localFile.childImageSharp
                .gatsbyImageData
            }
            alt=''
          />
        </div>
        <div
          className='uk-grid uk-grid-small'
          uk-scrollspy='cls: uk-animation-fade'
        >
          {foodImages}
        </div>
      </Section>
      <div className='uk-background-muted'>
        <Section>
          <div
            className='uk-flex uk-flex-column'
            uk-scrollspy='cls: uk-animation-fade'
          >
            <h3 className='uk-text-bold uk-width-medium'>화반 이용시간</h3>
            <h4 className='uk-margin-remove-top uk-margin-medium-bottom'>
              <Markdown
                source={data.allStrapiHwaban.nodes[0].hours}
                escapeHtml={false}
              />
            </h4>
            <h3 className='uk-text-bold uk-width-medium'>주소</h3>
            <h4 className='uk-margin-remove-top uk-margin-medium-bottom'>
              {data.allStrapiHwaban.nodes[0].description}
            </h4>
            <h3 className='uk-text-bold uk-width-medium'>
              단체예약 등 문의전화
            </h3>
            <h4 className='uk-margin-remove-top'>033) 638-4001</h4>
          </div>
        </Section>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    allStrapiHwaban {
      nodes {
        carousel_img {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        page_description
        description
        half_img1 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        half_img2 {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        main_img {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                transformOptions: { fit: COVER, trim: 2.33, cropFocus: CENTER }
                aspectRatio: 2.33
                placeholder: BLURRED
              )
            }
          }
        }
        menu_img {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                height: 950
                placeholder: BLURRED
              )
            }
          }
        }
        hours
      }
    }
  }
`;

export default HwabanPage;
